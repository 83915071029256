<template>
  <div class="container">
    <img class="icon" src="http://freesaasofficedata.oss.aliyuncs.com/miShop/20210512141636462.png" alt="">
    <div class="title">下单成功</div>
    <div class="order_no">订单编号：{{ orderNum }}</div>
    <div class="order-tips" v-if="brandId === 4 && isPrompt">
      <div class="prompt">特惠专区商品订单公司审核后，包含境外配送的商品将自动为您存入蔓薇库存，您可在大健康蔓薇库存账户选择自用取货</div>
    </div>
    <div class="btns">
      <div @click="goExchange" class="btn btn_">返回首页</div>
      <div @click="goOrder" class="btn">查看订单</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "success",
  data() {
    return {
      orderID: '',
      orderNum: '',
      brandId: 3,
      isPrompt:false
    }
  },
  created() {
    let { id } = this.$route.params;
    let { orderNum, brandId } = this.$route.query
    this.orderID = id;
    if (brandId) this.brandId = +brandId;
    this.orderNum = orderNum;
    this.get('/OrderGoods/PreferentialZone/SuccessOrder', {
      orderId:id
    }, 2).then(res => { 
      if (res.code === 1) { 
        this.isPrompt = res.response.isPrompt;
      }
    })
  },
  methods: {
    goOrder() {
      this.$router.push("/Recharge/order_detail?orderId=" + this.orderID + "&orderType=" + 12)
    },
    goExchange() {
      this.$router.push('/')
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  height: 100%;
  text-align: center;
  background-color: #f8f8f8;
  .order-tips {
    padding: 30px 15px;
    text-align: left;
    .prompt {
      color: #666666;
      font-size: 13px;
      .van-button {
        height: 14px;
        padding: 0 2px;
        font-size: 10px;
        margin-left: 3px;
        border-radius: 14px;
        display: inline-block;
        vertical-align: middle;
      }
    }
  }
  .btns {
    display: flex;
    align-items: center;
    margin-top: 40px;
    justify-content: space-around;
    padding-left: 30px;
    padding-right: 30px;

    .btn {
      padding: 8px 40px;
      border-radius: 500px;
      background-color: #ff98a0;
      color: #fff;
      box-sizing: border-box;
      border: 1px solid #ff98a0;
    }

    .btn_ {
      border-color: #e5e5e5;
      background-color: #fff;
      color: #999;
    }
  }

  .icon {
    width: 70px;
    padding-top: 30%;
  }

  .title {
    font-size: 30px;
    margin-top: 15px;
    color: #666666;
  }

  .order_no {
    margin-top: 6px;
    color: #666666;
    font-size: 14px;
  }
}
</style>